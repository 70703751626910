<!-- eslint-disable vue/no-v-html -->
<template>
  <b-card body>
    <div v-if="editMode === false">
      <div class="d-flex justify-between items-start mb-2">
        <div class="d-flex items-center align-self-center">
          <b-button
            variant="primary"
            size="sm"
            class="mr-1 rounded-lg p-0"
            @click="$router.go(-1)"
          >
            <feather-icon
              size="2x"
              icon="ChevronLeftIcon"
            />
          </b-button>
        </div>
        <div
          v-if="orderData.order_status === 'Dipacking' || orderData.order_status === 'Dikirim'"
          class="ml-1 p-[8px] d-flex bg-[#FFECE9] rounded-lg w-100"
        >
          <img
            class="w-6"
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/info-circle.svg"
            alt=""
          >
          <span class="ml-1">Order tidak dapat diedit{{ orderData.order_status === 'Dipacking' ? '' : ' atau dibatalkan' }} karena sudah dalam {{ orderData.order_status === 'Dipacking' ? 'status packing' : 'proses pengiriman oleh ekspedisi' }}.</span>
        </div>
        <div
          v-else
          class="d-flex align-self-center"
        >
          <span class="mr-1">Pengiriman via</span>
          <img
            class="w-30"
            src="https://storage.googleapis.com/komerce/assets/svg/komship-logo-yellow.svg"
            alt=""
          >
        </div>
      </div>
      <div class="d-flex justify-between mb-2">
        <div>
          <h3 class="font-bold d-inline-flex mb-0">
            Detail Order
          </h3>
        </div>
        <div class="d-flex gap-3">
          <b-button
            v-if="orderData.order_status === 'Diajukan'"
            :variant="orderData.is_crossborder === 1 ? 'secondary' : 'primary'"
            :disabled="orderData.is_crossborder === 1"
            @click="changeEditMode"
          >
            Edit
          </b-button>
          <b-button
            v-if="orderData.order_status === 'Diajukan'"
            variant="outline-primary"
            @click="cancelOrder"
          >
            Batalkan
          </b-button>
          <b-button
            v-if="orderData.order_status === 'Dipacking'"
            variant="outline-primary"
            @click="cancelPickup"
          >
            <b-spinner
              v-if="loadingCancelPickup"
              small
            />
            Batalkan
          </b-button>
        </div>
      </div>
      <b-overlay
        :show="loadingDetailOrder"
        spinner-variant="primary"
        variant="light"
        blur="0"
        opacity="0.3"
      >
        <b-container class="px-0">
          <h4 class="font-bold mb-1">
            Informasi Order
          </h4>
          <div class="border px-2 pt-2">
            <div
              v-if="(orderData.currency === 'MYR' && currency >= 500) || (orderData.currency === 'SGD' && currency >= 400)"
              class="d-flex py-[7px] px-1 bg-[#FFF2E2] rounded-xl mb-3"
            >
              <span class="k-info-circle-1 text-[#FBA63C] text-2xl mr-[5px]" />
              <small class="align-self-center">Pesananmu melebihi {{ orderData.currency }} {{ currency }} dan akan dikenakan biaya pajak dan bea cukai.
                <a
                  href="https://bantuan.komerce.id/id/article/paket-yang-terkena-bea-cukai-kirim-keluar-negeri-17jfsgz/?bust=1732094453050"
                  target="_blank"
                  class="text-[#626262] cursor-pointer hover:text-[#08A0F7] cursor-pointer"
                ><b>Pelajari selengkapnya</b></a></small>
            </div>
            <b-row class="mb-1">
              <b-col cols="6">
                No Order
              </b-col>
              <b-col
                cols="6"
                class="font-bold"
              >
                {{ orderData.order_no }}
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="6">
                Tanggal Order
              </b-col>
              <b-col
                cols="6"
                class="font-bold"
              >
                {{ DAY_MONTH_YEAR(orderData.order_date) }}
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="6">
                Gudang
              </b-col>
              <b-col
                v-if="orderData.is_kompack === 1"
                cols="6"
                class="font-bold"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/svg/logo_kompack.svg"
                  class="mr-[3px] w-[14px] h-[14px] d-inline-block"
                >
                {{ orderData.warehouse_name }}
              </b-col>
              <b-col
                v-else
                cols="6"
                class="font-bold"
              >
                <div
                  v-if="orderData.address_partner && orderData.address_partner.length > 0"
                >
                  {{ orderData.address_partner[0].name }}
                </div>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="6">
                Metode Pembayaran
              </b-col>
              <b-col
                cols="6"
                class="font-bold"
              >
                {{ orderData.payment_method }}
              </b-col>
            </b-row>
            <b-row class="mb-1 flex-wrap">
              <b-col cols="6">
                Status
              </b-col>
              <b-col
                cols="6"
                class=""
              >
                <b-alert
                  show
                  :variant="statusOrder"
                  class="px-1 w-64 text-center wrapper__status__order font-bold"
                  :style="orderData.order_status === 'Hilang' ? 'padding: 5px 0; background-color: #FCBEBE!important; color: #E31A1A!important' : 'padding: 5px 0;' "
                >
                  <span>{{ getOrderStatus(orderData) }}
                    <span
                      v-if="orderData.order_status === 'Retur'"
                      class="font-normal"
                    >
                      ({{ orderData.is_retur === 1 ? 'Dalam Perjalanan' : orderData.is_retur === 2 ? 'Diterima' : 'Luar Negeri' }})
                    </span>
                  </span>
                </b-alert>
                <b-badge
                  show
                  :variant="statusOrderMobile"
                  class="wrapper__status__order__mobile font-bold"
                >
                  <span>{{ getOrderStatus(orderData) }}
                    <span
                      v-if="orderData.order_status === 'Retur'"
                      class="font-normal"
                    >
                      ({{ orderData.is_retur === 1 ? 'Dalam Perjalanan' : orderData.is_retur === 2 ? 'Diterima' : 'Luar Negeri' }})
                    </span>
                  </span>
                </b-badge>
                <div
                  v-if="dataClaims.length !== 0"
                >
                  <div
                    v-for="(item, index) of dataClaims"
                    :key="index"
                    class="d-flex mb-[5px]"
                    :class="anotationClaimsClass(item.claim_status)"
                  >
                    <span class="align-self-center">
                      {{ getMessageClaims(item) }}
                    </span>
                    <img
                      v-if="item.claim_status === 2"
                      :id="'popover-claims-' + index"
                      class="ml-[5px] w-6"
                      src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                      alt="info"
                    >
                    <b-popover
                      :target="'popover-claims-' + index"
                      placement="top"
                      triggers="hover"
                      :content="item.notes"
                    />
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row
              v-if="orderData.is_crossborder === 1"
              class="mb-1"
            >
              <b-col cols="6">
                Pengiriman ke
              </b-col>
              <b-col
                cols="6"
                class="font-bold"
              >
                <div class="d-flex">
                  <div class="mr-[5px] align-content-center">
                    <img
                      class="w-[25px]"
                      :src="orderData.currency === 'SGD' ? 'https://flagcdn.com/w320/sg.png' : 'https://flagcdn.com/w320/my.png'"
                      alt="flag"
                    >
                  </div>
                  <div>{{ orderData.currency === 'SGD' ? 'Singapura' : 'Malaysia' }}</div>
                </div>
              </b-col>
            </b-row>
            <span class="d-flex mt-20 mb-1">Telah ditambahkan oleh ‘{{ userFullname }}’ pada {{ FULL_DATE_TIME(orderData.order_date) }} WIB</span>
            <span
              v-if="editBy !== null || editDate !== null"
              class="d-flex mb-6"
            >Telah diedit oleh ‘{{ editBy }}’ pada {{ FULL_DATE_TIME(editDate) }} WIB</span>
          </div>
          <h4 class="font-bold mt-2 mb-1">
            Informasi Pengiriman
          </h4>
          <div class="border px-2 pt-2 pb-1">
            <b-row class="mb-1">
              <b-col
                cols="12"
                md="6"
                class="mb-1"
              >
                <div class="d-flex">
                  <img
                    src="https://storage.googleapis.com/komerce/assets/icons/profile-placehold.svg"
                  >
                  <div class="ml-1 my-auto">
                    <span class="font-bold">{{ orderData.customer_name }}</span><br>
                    <span v-if="orderData.customer_phone && `${orderData.customer_phone}`.charAt(0) !== '0'">
                      {{ orderData.customer_country_code }}{{ orderData.customer_phone }}
                    </span>
                    <span v-else>{{ orderData.customer_phone }}</span>
                  </div>
                </div>
              </b-col>
              <b-col>
                <span
                  v-if="profile.is_komship === 1"
                  class="d-flex my-auto justify-center sm:justify-end"
                >
                  Pengiriman via
                  <div>
                    <b-img
                      src="https://storage.googleapis.com/komerce/assets/svg/komship-logo-yellow.svg"
                      style="margin-left:5px;"
                      alt="Komship"
                      fluid
                    />
                  </div>
                </span>
                <span v-else>
                  Pengiriman Non Komship
                </span>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col
                cols="6"
                md="6"
              >
                Ekspedisi
              </b-col>
              <b-col
                class="font-bold d-flex"
              >
                <img
                  :src="orderData.shipment_image_path"
                  style="width:20%; height: 100%;"
                ><span
                  class="my-auto"
                  style="margin-left:5px"
                >{{ orderData.shipment_alias }}</span>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col
                cols="6"
                md="6"
              >
                No Resi
              </b-col>
              <b-col
                class="font-bold d-flex"
              >
                {{ orderData.airway_bill }}
                <img
                  v-if="orderData.airway_bill"
                  src="https://storage.googleapis.com/komerce/assets/icons/copy.png"
                  class="copy-resi"
                  @click.prevent="copyResi(orderData.airway_bill)"
                >
                <span v-if="orderData.airway_bill === null">-</span>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col
                cols="6"
                md="6"
              >
                Detail Alamat
              </b-col>
              <b-col
                class="font-bold"
              >
                {{ orderData.customer_address }}
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col class="d-flex justify-content-end">
                <button
                  class="btn btn-outline-primary"
                  @click="lacakresi"
                >
                  Lacak resi
                </button>
                <b-modal
                  id="bv-modal-cek-resi"
                  ref="bv-modal-cek-resi"
                  size="lg"
                  hide-header
                  hide-footer
                >
                  <history-shipping-order
                    class="h-[80vh] overflow-y-auto overflow-x-hidden"
                    :awb="orderData.airway_bill"
                    :expedition="orderData.shipping"
                    @lostClaimable="handleLostClaimable"
                  >
                    <template
                      v-if="orderData.is_crossborder !== 1"
                      #footer="{ stuckDay }"
                    >
                      <div
                        class="bg-white h-[36px] py-[8px] w-full flex justify-center align-center"
                      >
                        <div class="flex w-fit shink-0 justify-between items-center gap-[12px]">
                          <div class="flex items-center gap-[4px]">
                            <span class="text-[#828282]">{{ statusClaimLostData.footerMessage }}</span>
                            <img
                              id="claim-info-icon"
                              src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                              alt="info"
                            >
                          </div>
                          <b-button
                            v-b-modal.modal-ajukan
                            :disabled="isHaveTicketClaimLost || !isLostClaimable"
                            class="disabled:text-[#C2C2C2!important] disabled:border-[#C2C2C2!important]"
                            size="sm font-[600!important]"
                            variant="outline-primary"
                          >
                            Ajukan
                          </b-button>
                          <b-tooltip
                            target="claim-info-icon"
                            triggers="hover"
                            variant="light"
                            custom-class="drop-shadow-lg"
                          >
                            <p
                              v-if="orderData.order_status === 'Hilang' && isHaveTicketClaimLost === false"
                              class="mb-0 text-left text-[14px] text-[#828282]"
                            >
                              Paketmu telah diselesaikan melalui tiket ini
                            </p>
                            <p
                              v-else
                              class="mb-0 text-left text-[14px] text-[#828282]"
                            >
                              {{ getTooltipClaim(stuckDay) }}
                            </p>
                          </b-tooltip>
                        </div>
                      </div>
                    </template>
                  </history-shipping-order>
                </b-modal>
              </b-col>
            </b-row>
          </div>
          <h4 class="font-bold mt-2 mb-1">
            Informasi Penjualan
          </h4>
          <div class="border pb-2">
            <b-table
              responsive
              :fields="fieldOrder"
              :items="itemOrder"
            >
              <template #cell(no)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(product_name)="data">
                <h5 class="text-black">
                  <strong>
                    {{ data.item.product_name }}
                  </strong>
                </h5>
                <div
                  v-if="data.item.is_bundling === 1"
                  class="flex items-center gap-[4px] py-[4px] px-[8px] rounded-[12px] !bg-[#08A0F7] text-white font-[500] cursor-pointer mt-[5px] w-[7rem]"
                  @click="$bvModal.show(`bundling-${String(data.index)}-${String(data.item.product_id)}`)"
                >
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/gift.svg"
                    alt="bundling"
                    width="16"
                  >
                  <div>Bundling</div>
                </div>
                <h4
                  v-else
                  class="text-primary"
                >
                  <strong>
                    {{ data.item.variant_name }}
                  </strong>
                </h4>
                <modal-bundling-quantity
                  v-if="data.item.is_bundling === 1"
                  :data="data.item"
                  :index="data.index"
                />
              </template>
              <template #cell(price)="data">
                {{ IDR(data.item.price) }}
              </template>
              <template #cell(subtotal)="data">
                {{ IDR(data.item.price * data.item.qty) }}
              </template>
            </b-table>
            <hr>
            <b-row
              v-if="orderData.order_notes !== '0' && orderData.order_notes !== '' && orderData.order_notes !== null"
              class="px-2"
            >
              <b-col
                cols="12"
                class="mb-1 text-[16px] font-medium"
              >
                <span class="d-flex">Catatan Order</span>
              </b-col>
              <b-col cols="12">
                <div
                  class="border px-2 pt-1 pb-3"
                  style="width:100%"
                >
                  {{ orderData.order_notes }}
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col
                lg="5"
                offset-lg="3"
              >
                Total Harga Produk
              </b-col>
              <b-col
                class="d-flex justify-start sm:justify-end"
                lg="3"
              >
                {{ IDR(orderData.subtotal) }}
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col
                lg="5"
                offset-lg="3"
              >
                Ongkos Kirim ({{ orderData.total_weight }} Kg)
              </b-col>
              <b-col
                lg="3"
                class="d-flex justify-start sm:justify-end"
              >
                {{ IDR(orderData.shipping_cost) }}
              </b-col>
            </b-row>
            <b-row
              v-if="orderData.is_crossborder !== 1"
              class="mt-1"
            >
              <b-col
                lg="5"
                offset-lg="3"
              >
                Potongan Harga
              </b-col>
              <b-col
                lg="3"
                class="d-flex justify-start sm:justify-end"
              >
                -  {{ IDR(orderData.discount) }}
              </b-col>
            </b-row>
            <b-row
              v-if="orderData.is_crossborder !== 1"
              class="mt-1"
            >
              <b-col
                lg="5"
                offset-lg="3"
              >
                Biaya Lain
              </b-col>
              <b-col
                lg="3"
                class="d-flex justify-start sm:justify-end"
              >
                {{ IDR(orderData.additional_cost) }}
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col
                lg="8"
                offset-lg="3"
              >
                <hr><span />
              </b-col>
            </b-row>
            <b-row
              v-if="orderData.is_crossborder !== 1"
              class="mt-1"
              :class="orderData.order_status === 'Retur' ? 'line-through' : ''"
            >
              <b-col
                lg="5"
                class="d-flex justify-end sm:justify-start font-bold text-xl"
                offset-lg="3"
              >
                Total Pembayaran ({{ orderData.payment_method }}) :
              </b-col>
              <b-col
                lg="3"
                class="d-flex justify-start sm:justify-end font-bold text-primary text-xl"
              >
                {{ IDR(orderData.grandtotal) }}
              </b-col>
            </b-row>
            <div
              v-else
              :class="orderData.order_status === 'Retur' ? 'line-through' : ''"
            >
              <b-row
                class="mt-1"
              >
                <b-col
                  lg="5"
                  offset-lg="3"
                  class="font-semibold text-xl"
                >
                  Total Pembayaran ({{ orderData.payment_method }}) :
                </b-col>
              </b-row>
              <b-row
                class="mt-1"
              >
                <b-col
                  lg="8"
                  offset-lg="3"
                  class="text-sm text-primary"
                >
                  <span style="text-decoration: none !important; display: inline-block;">*Nilai pembayaran sudah disesuaikan dengan fitur input cepat</span>
                </b-col>
              </b-row>
              <b-row
                class="mt-1"
              >
                <b-col
                  lg="5"
                  offset-lg="3"
                >
                  <div class="d-flex">
                    <span class="mr-1">Nilai {{ orderData.currency }}</span>
                    <img
                      id="popover-currency"
                      src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                      alt=""
                    >
                    <b-popover
                      triggers="hover focus"
                      target="popover-currency"
                      placement="topright"
                    >
                      <strong>Nilai {{ orderData.currency }}</strong>
                      <div class="mt-1">
                        Merupakan Nilai {{ orderData.payment_method === 'COD' ? 'COD yang akan ditagihkan ke Pembeli' : `Transaksi dalam ${ country === 'my' ? 'Ringgit Malaysia' : 'Dolar Singapura' }` }}
                      </div>
                    </b-popover>
                  </div>
                </b-col>
                <b-col
                  lg="3"
                  class="d-flex justify-start sm:justify-end text-primary font-semibold"
                >
                  {{ orderData.currency }} {{ currency }}
                </b-col>
              </b-row>
              <b-row
                v-if="getCrossBorderStatus(orderData.order_status)"
                class="mt-1"
              >
                <b-col
                  lg="5"
                  offset-lg="3"
                >
                  <div class="d-flex">
                    <span class="mr-1">Estimasi IDR</span>
                    <img
                      id="popover-idr"
                      src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                      alt=""
                    >
                    <b-popover
                      triggers="hover focus"
                      target="popover-idr"
                      placement="topright"
                    >
                      <strong>Estimasi IDR</strong>
                      <div class="mt-1">
                        Merupakan estimasi nilai {{ orderData.payment_method === 'COD' ? 'COD' : 'transaksi Non-COD' }} konversi kurs ke IDR di waktu ketika Submit Order
                      </div>
                    </b-popover>
                  </div>
                </b-col>
                <b-col
                  lg="3"
                  class="d-flex justify-start sm:justify-end"
                >
                  {{ IDR(orderData.grandtotal) }}
                </b-col>
              </b-row>
              <b-row
                class="mt-1"
              >
                <b-col
                  lg="5"
                  offset-lg="3"
                >
                  <div class="d-flex">
                    <span class="mr-1">Nilai Akhir IDR</span>
                    <img
                      id="popover-count-idr"
                      src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                      alt=""
                    >
                    <b-popover
                      triggers="hover focus"
                      target="popover-count-idr"
                      placement="topright"
                    >
                      <strong>Nilai Akhir IDR</strong>
                      <div class="mt-1">
                        <div v-if="orderData.payment_method === 'COD'">
                          Merupakan nilai COD akhir (final) hasil dari konversi kurs IDR ketika status order Diterima.
                          <div class="font-semibold">
                            Ketika order hilang / rusak akan menggunakan nilai Estimasi IDR.
                          </div>
                        </div>
                        <div v-else>
                          Merupakan Nilai IDR dari Transaksi Non-COD akhir (nilai sama seperti saat Submit Order)
                        </div>
                      </div>
                    </b-popover>
                  </div>
                </b-col>
                <b-col
                  lg="3"
                  class="d-flex justify-start sm:justify-end"
                >
                  <div
                    v-if="getCrossBorderStatus(orderData.order_status)"
                    class="d-flex"
                  >
                    <span class="text-secondary mr-[5px]"><i>Menunggu</i></span>
                    <span class="k-clock-1 text-secondary align-self-center" />
                  </div>
                  <div v-else>
                    {{ IDR(orderData.grandtotal) }}
                  </div>
                </b-col>
              </b-row>
            </div>
            <b-row
              v-if="orderData.is_mass_order === 1"
              class="mt-1"
            >
              <b-col
                lg="5"
                class="font-bold text-sm text-primary"
                offset-lg="3"
              >
                {{ orderData.notes }}
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col
                lg="7"
                offset-lg="3"
              >
                <hr><span />
              </b-col>
              <b-col
                lg="2"
                class="d-flex justify-start"
              >
                <b-button
                  v-b-toggle="'collapse-1'"
                  class="buttonCollapse px-0"
                  variant="none"
                  size="sm"
                >
                  <span class="when-open">Tutup <b-icon-chevron-up /></span>
                  <span class="when-closed">Buka <b-icon-chevron-down /></span>
                </b-button>
              </b-col>
            </b-row>
            <b-collapse id="collapse-1">
              <b-row class="mt-1">
                <b-col
                  lg="5"
                  offset-lg="3"
                >
                  Biaya {{ orderData.payment_method }} ({{ orderData.service_fee_to }}% sudah termasuk PPN)
                </b-col>
                <b-col
                  lg="3"
                  class="d-flex justify-start sm:justify-end"
                >
                  <span>-  {{ IDR(orderData.service_fee) }}</span>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col
                  lg="5"
                  offset-lg="3"
                >
                  Ongkos Kirim (dipotong Cashback {{ orderData.cashback_to }}%)
                </b-col>
                <b-col
                  lg="3"
                  class="d-flex justify-start sm:justify-end"
                >
                  -  {{ IDR(orderData.shipping_cost - orderData.shipping_cashback) }}
                </b-col>
              </b-row>
              <b-row
                v-if="orderData.order_status === 'Retur'"
                class="mt-1"
              >
                <b-col
                  lg="5"
                  offset-lg="3"
                >
                  Ongkos Kirim Pengembalian (diskon {{ orderData.percentage_cost_retur }}%)
                </b-col>
                <b-col
                  lg="3"
                  class="d-flex justify-start sm:justify-end"
                >
                  -  {{ IDR(orderData.shipping_retur) }}
                </b-col>
              </b-row>
              <b-row
                v-if="checkedInsurance"
                class="mt-1"
              >
                <b-col
                  lg="5"
                  offset-lg="3"
                >
                  <div class="d-flex">
                    <span class="mr-1">Biaya Asuransi</span>
                    <img
                      id="popover-asuransi"
                      src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                      alt=""
                    >
                    <b-popover
                      triggers="hover focus"
                      target="popover-asuransi"
                      placement="topright"
                    >
                      <a
                        href="https://bantuan.komerce.id/id/article/asuransi-pengiriman-ekspedisi-di-komship-17pow0b/?bust=1678871763794"
                        class="text-[#08A0F7]"
                        target="_blank"
                      ><u>Klik disini</u></a> buat detail tarifnya.
                    </b-popover>
                  </div>
                </b-col>
                <b-col
                  lg="3"
                  class="d-flex justify-start sm:justify-end"
                >
                  -  {{ IDR(insurancePrice) }}
                </b-col>
              </b-row>
              <b-row
                v-if="orderData.is_crossborder === 1"
                class="mt-1"
              >
                <b-col
                  lg="5"
                  offset-lg="3"
                >
                  <div class="d-flex">
                    <span class="mr-1">Biaya VAT 11%</span>
                    <img
                      id="popover-vat"
                      src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                      alt=""
                    >
                    <b-popover
                      triggers="hover focus"
                      target="popover-vat"
                      placement="topright"
                    >
                      <strong>VAT (Value Added Tax)</strong>
                      <div class="mt-1">
                        VAT = 11% dari Biaya Ongkir dan Fee COD
                      </div>
                    </b-popover>
                  </div>
                </b-col>
                <b-col
                  lg="3"
                  class="d-flex justify-start sm:justify-end"
                >
                  -  {{ IDR(orderData.vat) }}
                </b-col>
              </b-row>
            </b-collapse>
            <b-row class="mt-1">
              <b-col
                lg="6"
                offset-lg="3"
                class="font-bold"
              >
                {{ orderData.is_crossborder === 1 && getCrossBorderStatus(orderData.order_status) ? 'Estimasi penghasilan' : 'Penghasilan' }} bersih yang kamu dapatkan
              </b-col>
              <b-col
                v-if="statusNetProfit === '-'"
                lg="2"
                class="d-flex justify-start sm:justify-end text-primary font-bold"
              >
                {{ IDR(orderData.net_profit) }}
              </b-col>
              <b-col
                v-else
                lg="2"
                class="d-flex justify-start sm:justify-end text-success font-bold"
              >
                {{ IDR(orderData.net_profit) }}
              </b-col>
            </b-row>
          </div>
        </b-container>
      </b-overlay>

      <!-- Popup success cancel pickup -->
      <b-modal
        ref="popup-success-cancel-pickup"
        hide-footer
        hide-header
        no-close-on-backdrop
        no-close-on-esc
        centered
      >
        <b-row class="justify-content-center mb-2 pt-2">
          <b-img src="https://storage.googleapis.com/komerce/assets/icons/success.svg" />
        </b-row>

        <b-row class="justify-content-center">
          <h4 class="text-black text-center">
            <strong>
              Order Berhasil Dibatalkan
            </strong>
          </h4>
        </b-row>

        <b-row class="justify-content-center mb-1">
          <span class="text-black text-center">
            Order dan pengajuan pickup berhasil dibatalkan.
          </span>
        </b-row>

        <b-row class="justify-content-center pb-1">
          <b-button
            variant="primary"
            class="btn-icon"
            @click="fetchData"
          >
            Oke
          </b-button>
        </b-row>
      </b-modal>
    </div>

    <!-- Edit Order -->
    <edit-order
      v-if="editMode"
      :id-order="orderData.order_id"
      @changeValueEditMode="applyUpdateEditMode"
    />

    <ModalSubmitClaimLost
      :order-id="orderId"
      :ticket-id="ticketID"
    />

    <ModalSuccessClaimLost @on-click-ok="onClickSuccessClaimLost" />

  </b-card>
</template>
<script>
/* eslint-disable global-require */
import {
  BCard, BRow, BButton, BIconChevronLeft, BContainer, BCol, BAlert, VBModal, BTable, BCollapse, BBadge, BImg,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import HistoryShippingOrder from '@core/components/popup-lacak-resi/HistoryShippingOrder.vue'
import { mapState } from 'vuex'
import ModalSubmitClaimLost from '@/views/pages/komship/detail-ticketing-user/ModalSubmitClaim.vue'
import ModalSuccessClaimLost from '@/views/pages/komship/detail-ticketing-user/ModalSuccessClaim.vue'
import { FULL_DATE_TIME, DAY_MONTH_YEAR } from '@/libs/formatDate'
import { IDR } from '@/libs/currency'
import { getMessageClaims, anotationClaimsClass } from '@/libs/label-claims'
import ModalBundlingQuantity from '@/views/components/modal/ModalBundling.vue'
import EditOrder from '../EditOrder/EditOrder.vue'

export default {
  components: {
    BCard, BRow, BButton, BContainer, BCol, BAlert, BTable, BCollapse, EditOrder, BBadge, BImg, HistoryShippingOrder, ModalSuccessClaimLost, ModalSubmitClaimLost, ModalBundlingQuantity,
  },
  directives: { VBModal },
  data() {
    return {
      orderData: [],
      statusOrder: null,
      statusOrderMobile: null,
      fieldOrder: [
        { key: 'no', label: 'No' },
        { key: 'product_name', label: 'Nama Produk' },
        { key: 'price', label: 'Harga Satuan' },
        { key: 'qty', label: 'Jumlah' },
        { key: 'subtotal', label: 'Sub Total' },
      ],
      itemOrder: [],
      statusNetProfit: null,
      idEditOrder: 0,

      loadingDetailOrder: false,
      loadingCancelPickup: false,

      editMode: false,

      editBy: null,
      editDate: null,

      userFullname: '',

      checkedInsurance: true,
      insurancePrice: 0,
      statusClaimLost: null,
      isHaveTicketClaimLost: false,
      isLostClaimable: false,

      orderId: null,
      ticketID: null,

      classClaims: '',
      dataClaims: [],

      currency: 0,

      FULL_DATE_TIME,
      DAY_MONTH_YEAR,
      IDR,
      getMessageClaims,
      anotationClaimsClass,
    }
  },
  computed: {
    statusClaimLostData() {
      let footerMessage = 'Belum Ajukan Claim'

      // eslint-disable-next-line default-case
      switch (this.statusClaimLost) {
        // eslint-disable-next-line no-lone-blocks
        case 0: {
          if (this.isHaveTicketClaimLost) {
            footerMessage = 'Claim Sedang Direview'
          }
        }
          break

        case 1:
          footerMessage = 'Claim Disetujui'
          break

        case 2:
          footerMessage = 'Claim Ditolak'
          break
      }

      return {
        footerMessage,
      }
    },
    ...mapState('dashboard', ['profile']),
  },
  mounted() {
    this.idEditOrder = this.$route.params.order_id
    this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        this.loadingDetailOrder = true
        this.$refs['popup-success-cancel-pickup'].hide()
        const order = await this.$http_komship.get(`v1/order/${this.profile.partner_id}/detail/${this.$route.params.order_id}`)
        const { data } = await order.data
        this.orderData = await data
        this.editBy = data.user_name_edited
        this.editDate = data.order_edited_date
        this.statusNetProfit = data.net_profit.toString().charAt(0)
        this.itemOrder = await data.product
        this.statusOrder = await this.setAlert(data.order_status)
        this.statusOrderMobile = await this.setAlertMobile(data.order_status)
        this.loadingDetailOrder = false
        this.userFullname = data.user_fullname
        this.insurancePrice = data.insurance_fee
        this.currency = data.currency_value
        if (data.is_insurance === 1) {
          this.checkedInsurance = true
        } else {
          this.checkedInsurance = false
        }
        this.orderId = data.order_id

        if (data.claim_data !== null) {
          this.statusClaimLost = data.claim_data.filter(item => item.claim_type === 'hilang').claim_status
          this.isHaveTicketClaimLost = data.claim_data.some(item => item.claim_type === 'hilang')
          this.dataClaims = data.claim_data.filter(item => item.claim_type !== 'retur' && item.claim_type !== 'hilang')
        }
        this.ticketID = String(data.ticket_id)
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failure',
            icon: 'AlertCircleIcon',
            text: err,
            variant: 'danger',
          },
        }, 2000)
        this.loadingDetailOrder = false
      }
    },
    setAlertMobile(status) {
      if (status === 'Diajukan') {
        this.statusOrderMobile = 'light-primary'
      } else if (status === 'Dipacking') {
        this.statusOrderMobile = 'light-info'
      } else if (status === 'Dikirim') {
        this.statusOrderMobile = 'light-warning'
      } else if (status === 'Diterima') {
        this.statusOrderMobile = 'light-success'
      } else if (status === 'Batal') {
        this.statusOrderMobile = 'light-dark'
      } else if (status === 'Retur' || status === 'Hilang' || status === 'Rusak') {
        this.statusOrderMobile = 'light-danger'
      }
      return this.statusOrderMobile
    },
    setAlert(status) {
      if (status === 'Diajukan') {
        this.statusOrder = 'primary'
      } else if (status === 'Dipacking') {
        this.statusOrder = 'info'
      } else if (status === 'Dikirim') {
        this.statusOrder = 'warning'
      } else if (status === 'Diterima') {
        this.statusOrder = 'success'
      } else if (status === 'Batal') {
        this.statusOrder = 'dark'
      } else if (status === 'Retur' || status === 'Hilang' || status === 'Rusak') {
        this.statusOrder = 'danger'
      }
      return this.statusOrder
    },
    cancelOrder() {
      this.$swal.fire({
        title: 'Batal Order',
        text: 'Kamu yakin ingin membatalkan Order?',
        imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/warning.svg',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        reverseButtons: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Kembali',
        cancelButtonColor: '#FFFFFF',
        confirmButtonClass: 'btn btn-primary w-40',
        cancelButtonClass: 'btn btn-outline-primary text-primary w-40',
      }).then(isConfirm => {
        if (isConfirm.value === true) {
          this.$http_komship.delete(`v1/order/${this.profile.partner_id}/delete/${this.$route.params.order_id}`)
            .then(() => {
              this.$swal.fire({
                title: 'Batal Order',
                text: 'Order Berhasil Dibatalkan',
                imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/success.svg',
                showLoaderOnConfirm: true,
                confirmButtonText: 'Oke',
                confirmButtonClass: 'btn btn-primary w-100',
              }).then(
                this.fetchData(),
                this.$router.push({ path: '/data-order' }),
              )
            })
            .catch(err => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Failure',
                  icon: 'AlertCircleIcon',
                  text: err,
                  variant: 'danger',
                },
              }, 2000)
            })
        }
      })
    },
    async copyResi(resi) {
      try {
        await navigator.clipboard.writeText(resi)
        const Toast = this.$swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          didOpen: toast => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer)
            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          },
        })

        Toast.fire({
          icon: 'success',
          title: '<span class="text-success">Success copy to clipboard</span>',
          showCloseButton: true,
        })
      // eslint-disable-next-line no-empty
      } catch ($e) {
      }
    },
    cancelPickup() {
      // eslint-disable-next-line global-require
      const logoWarning = 'https://storage.googleapis.com/komerce/assets/icons/popup-warning.png'
      this.$swal.fire({
        text: 'Orderan ini telah diajukan pickup, Apakah kamu yakin untuk membatalkan orderan ?',
        imageUrl: logoWarning,
        showCancelButton: true,
        showLoaderOnConfirm: true,
        reverseButtons: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Kembali',
        cancelButtonColor: '#FFFFFF',
        confirmButtonClass: 'btn btn-primary w-40',
        cancelButtonClass: 'btn btn-outline-primary text-primary w-40',
      }).then(isConfirm => {
        if (isConfirm.value === true) {
          this.loadingCancelPickup = true
          this.$http_komship.put(`/v2/order/${this.profile.partner_id}/cancel/${this.idEditOrder}`)
            .then(() => {
              this.loadingCancelPickup = false
              this.$refs['popup-success-cancel-pickup'].show()
            })
            .catch(err => {
              this.loadingCancelPickup = false
              this.$swal.fire({
                text: 'Mohon maaf orderan ini tidak dapat dibatalkan, karena sudah dalam proses pengiriman oleh kurir',
                imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/fail.svg',
                showLoaderOnConfirm: true,
                confirmButtonText: 'Oke',
                confirmButtonClass: 'btn btn-primary w-100',
              }).then(
                this.fetchData(),
              )
            })
        }
      })
    },

    changeEditMode() {
      this.editMode = true
    },
    applyUpdateEditMode() {
      this.editMode = false
    },
    lacakresi() {
      this.$bvModal.show('bv-modal-cek-resi')
    },
    onClickSuccessClaimLost() {
      this.$bvModal.hide('modal-success-claim')
      this.$router.push(`/ticketing/detail/${this.ticketID}?filter_status=0`)
    },
    handleLostClaimable(data) {
      this.isLostClaimable = data
    },
    getTooltipClaim(stuckDay) {
      const result = `Paketmu sudah stuck ${stuckDay} hari.\n`
      let text = ''
      if (stuckDay <= 30 || (this.orderData.shipping !== 'IDEXPRESS' && stuckDay <= 60)) {
        text = 'Memungkinkan kamu bisa claim paket hilang, lalu saldo akan bertambah sesuai penghasilan order'
      } else {
        text = `Sudah melewati masa claim (maksimal mengajukan claim ${this.orderData.shipping === 'IDEXPRESS' ? 30 : 60} Hari setelah stuck dari status perjalanan terakhir)`
      }
      return result + text
    },
    getOrderStatus(value) {
      let result
      if (value.order_status === 'Diajukan') {
        result = 'Order Dibuat'
      } else if (value.order_status === 'Batal') {
        result = 'Dibatalkan'
      } else if (value.order_status === 'Hilang') {
        result = 'Paket Hilang'
      } else if (value.order_status === 'Rusak') {
        result = 'Paket Rusak'
      } else if (value.order_status === 'Retur') {
        result = 'Retur'
      } else {
        result = value.order_status
      }
      return result
    },
    getCrossBorderStatus(status) {
      const listStatus = ['Diajukan', 'Dipacking', 'Dikirim']
      const show = listStatus.includes(status)
      return show
    },
  },
}
</script>
<style lang="css">
.copy-resi{
  margin-left: 3px;
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
/* Steps */
.steps {
  position: relative;
  min-height: 1em;
  color: gray;
}
.steps + .steps {
  margin-top: 1.5em
}
.steps > div:first-child {
  position: static;
  height: 0;
}
.steps > div:not(:first-child) {
  margin-left: 1.5em;
  padding-left: 1em;
}
.steps.step-actives {
  color: #333333,
}
.steps.step-actives .circles {
  background-color: #f95031;
}

/* Circle */
.circles {
  background: gray;
  position: relative;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  box-shadow: 0 0 0 3px #fff;
}

/* Vertical Line */
.circles:after {
  content: ' ';
  position: absolute;
  display: block;
  top: 1px;
  right: 50%;
  bottom: 1px;
  left: 50%;
  height: 100%;
  width: 1px;
  transform: scale(1, 2);
  transform-origin: 50% -100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: -1;
}
.steps:last-child .circles:after {
  display: none
}

/* Stepper Titles */
.titles {
  line-height: 1.5em;
  font-weight: 900 !important;
  color: #333333 !important;
}
.captions {
  font-size: 0.8em;
  color: #929292;
}

.wrapper__status__order__mobile {
  display: none!important;
}

@media only screen and (max-width: 576px) {
    [dir] .wrapper__status__order {
        display: none!important;
    }

    [dir] .wrapper__status__order__mobile {
        display: inline-block!important;
    }
}
</style>
